@import 'styles/library.scss';

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
.discovery-investigation-action-dropdown {
  .sub-menu-title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;

    .submenu-icon {
      position: relative;
      top: 2px;
      transform: scale(1.1) rotate(180deg);
      margin-right: 0px;
    }
  }
  & .MuiPaper-root {
    overflow: hidden;
    top: 125px !important;
    right: 22px !important;
    left: auto !important;
    border: 1px solid #ecedff;
    border-radius: 6px;
    box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05),
      0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04),
      0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
    .main-menu-wrapper {
      width: 170px;
      height: 130px;
      padding: 8px;
      & .main-menu-item {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        &:hover {
          background-color: #f6f8ff80;
          cursor: pointer;
        }
        .submenu-icon {
          position: relative;
          top: 1px;
          transform: scale(1.2);
          margin-left: 24px;
        }
      }
    }
    .sub-menu-wrapper {
      display: flex;
      flex-direction: column;
      width: 160px;
      margin: 8px;

      .info-icon {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
      .toggle-wrapper {
        flex-grow: 1;

        &.toggle-disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      .add-comments-item {
        display: flex;
        align-items: center;
        padding: 8px;
        margin: 4px;
        &:hover {
          background-color: #f6f8ff80;
          cursor: pointer;
        }
      }

      .verdict-disabled,
      .comments-disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .ToggleButtons {
        margin-right: 0px !important;
        height: 22px;
        flex: auto !important;
      }
      .ToggleButtons > span {
        display: flex;
        justify-content: center;
        width: 50% !important;
      }

      .more-actions {
        display: flex;
        align-items: center;
        margin: 12px 0 6px 14px;
        &:hover {
          cursor: pointer;
        }
      }
      & .option-item {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        &:hover {
          background-color: #f6f8ff80;
          cursor: pointer;
        }
      }
      .submenu-icon {
        position: relative;
        top: 1px;
        transform: scale(1.2);
        margin-left: 12px;
      }
    }
  }
}

.DiscoveryScanReport {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;

  &.support-multiline {
    & .MuiDataGrid-cell {
      line-height: unset !important;
      max-height: none !important;
      white-space: normal !important;
    }
    & .MuiDataGrid-row {
      max-height: none !important;
    }
  }
  .header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 16px;
    margin-bottom: 16px;
    .icon-date {
      display: flex;
      align-items: center;
      svg {
        transform: scale(1.5);
      }
    }
  }
  .gridUtilities_right-side {
    button {
      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
  .investigation-tool-content {
    height: calc(100vh - 70px);
    margin: 0 8px;

    &.no-events {
      pointer-events: none;
      opacity: 0.5;
    }
    .gridUtilities_input-scope {
      display: none;
    }

    .MuiDataGrid-pinnedColumnHeaders {
      .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--resizable {
        display: block !important;
      }
    }
  }
  .footer-wrapper {
    display: flex;
    flex-direction: column;
    height: 50px;
    border-top: 1px solid #e0e0e0;

    .footer {
      position: fixed;
      bottom: 0;
      height: 50px;
      background-color: #fff;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      .submit {
        margin-right: 16px;
      }
    }
  }
  .drawer-wrapper {
    display: flex;
    border-right: 1px solid #e0e0e0;
    background-color: #fff;
    transition: 0.3s width;

    &.closed {
      width: 50px;
      .drawer-icon {
        svg {
          transform: scale(1.4);
        }
      }
    }
    &.open {
      width: 400px;
    }
  }
  .SaveCustomView {
    position: absolute;
    left: 190px !important;
    bottom: 92px !important;
    top: auto !important;
    box-shadow: 0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05),
      0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04),
      0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02);
  }
}
