.top-panel-loading-state {
  position: sticky;
  // height: 155px !important;
  height: 145px;
  z-index: 2;
  background-color: #2c2c30;
  border: 1px solid #454549;
  border-radius: 12px 12px 0 0;

  &.open {
    height: 145px;
    .top-panel-loading-container {
      height: 145px !important;
    }
  }
  &.closed {
    height: 55px;
    .top-panel-loading-container {
      height: 55px !important;
    }
  }
}
