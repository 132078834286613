.AddAssetsPopUp.parkedDomains {
  .popup-inner {
    padding: 1rem 2rem;
  }
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-size: 1.5rem !important;
  }
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    width: 100%;
  }
}